import React, { FC, useState } from 'react';
import { Simulate } from "react-dom/test-utils"
import { BrowserRouter, Route, Routes, useSearchParams } from "react-router-dom"
import logo from './logo.svg';
import './App.css';
import { List } from "immutable"
import gaussian from "gaussian"
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { Field, Form, Formik } from 'formik';


const USE_DRIFT = true || (!!process.env.REACT_APP_USE_DRIFT || false)

interface SimulationProps {
  price: number
  priceVolatility: number
  priceDrift?: number

  backingPrice: number
  backingPriceVolatility: number
  backingPriceDrift?: number

  apy: number
  rewardVolatility: number
  rewardDrift?: number

  initialTokens: number
  initialPrice: number
  runwayInDays: number
}

interface SimulationResult {
  tokens: number[]
  price: number[]
  backingValue: number[]
  reward: number[]
  returns: number[]
  value: number[]
}

const apyToDailyReward = (apy: number): number => {
  return Math.pow(apy - 1, (1 / 365)) - 1
}

class SimulationTimeSeries {
  private props: SimulationProps
  private results: SimulationResult
  private useDrift: boolean

  constructor(props: SimulationProps, useDrift: boolean) {
    this.useDrift = useDrift
    this.props = props
    this.results = {
      price: [],
      backingValue: [],
      reward: [],
      returns: [],
      tokens: [],
      value: []
    }
  }

  simulate(): SimulationResult {
    this.results = {
      price: [this.props.price],
      backingValue: [this.props.backingPrice],
      reward: [apyToDailyReward(this.props.apy)],
      returns: [(this.props.price * this.props.initialTokens) / (this.props.initialPrice * this.props.initialTokens)],
      tokens: [this.props.initialTokens],
      value: [this.props.price * this.props.initialTokens]
    }

    for (let i = 0; i < this.props.runwayInDays; i++) {
      this.results.reward.push(this.nextReward())
      this.results.backingValue.push(this.nextBackedPrice())
      this.results.price.push(this.nextPrice())
      this.results.tokens.push(this.nextTokens())
      this.results.value.push(this.currentTokens() * this.currentPrice())
      this.results.returns.push(this.currentValue() / (this.props.initialTokens * this.props.initialPrice))
    }

    return { ...this.results }

  }

  nextPrice() {
    const std = Math.max(this.currentPrice() * this.props.priceVolatility, 0.001)
    const dist = gaussian(this.currentPrice() * this.priceDrift(), std * std)
    return Math.max(
      this.currentPrice() + dist.ppf(Math.random()),
      this.currentBackedPrice()
    )
  }

  currentPrice() {
    return this.results.price[this.results.price.length - 1]
  }

  currentBackedPrice() {
    return this.results.backingValue[this.results.backingValue.length - 1]
  }

  nextBackedPrice() {
    const std = this.currentBackedPrice() * this.props.backingPriceVolatility
    const variance = std * std
    const dist = gaussian(this.currentBackedPrice() * this.backedPriceDrift(), variance)
    return Math.max(this.currentBackedPrice() + dist.ppf(Math.random()), 1.0)
  }

  nextReward() {
    const std = this.props.rewardVolatility
    const variance = std * std
    const mean = this.rewardDrift() * this.currentReward()

    const dist = gaussian(mean, Math.max(variance, 0.00001))

    return Math.max(this.currentReward() * (1 + dist.ppf(Math.random())), 0.00001)
  }

  currentReward() {
    return this.results.reward[this.results.reward.length - 1]
  }

  nextTokens() {
    return this.currentTokens() + this.currentReward() * this.currentTokens()
  }

  currentTokens() {
    return this.results.tokens[this.results.tokens.length - 1]
  }

  currentValue() {
    return this.results.value[this.results.value.length - 1]
  }

  private priceDrift() {
    if (this.useDrift) {
      return this.props.priceDrift || 0
    } else {
      return 0
    }
  }

  private backedPriceDrift() {
    if (this.useDrift) {
      return this.props.backingPriceDrift || 0
    } else {
      return 0
    }
  }

  private rewardDrift() {
    if (this.useDrift) {
      return this.props.rewardDrift || 0
    } else {
      return 0
    }
  }
}

const DataTable: FC<{ result: SimulationResult, steps: number[] }> = ({ result, steps }) => {
  return (
    <table>
      <thead>
      <th>Rebase</th>
      <th>Price</th>
      <th>Backed Price</th>
      <th>Reward</th>
      <th>Tokens</th>
      </thead>
      <tbody>
      {
        steps.map((_, idx) => {
          return (<tr key={idx}>
            <td>{idx}</td>
            <td>{result.price[idx].toFixed(2)}</td>
            <td>{result.backingValue[idx].toFixed(2)}</td>
            <td>{(result.reward[idx] * 100).toFixed(3)}%</td>
            <td>{result.tokens[idx].toFixed(8)}</td>
          </tr>)
        })
      }
      </tbody>
    </table>
  )
}

const NumberInput: FC<{
  label: string,
  name: string,
  value?: number | string,
  max?: number,
  min?: number,
  step?: number
}> = ({
        label, name,
        value,
        max,
        min,
        step
      }) => {
  return (
    <label className="block">
      <span className="text-gray-700">{label}</span>
      <Field name={name} {...{ max, min, step }} type={"number"} className="form-input mt-1 block w-full"/>
    </label>
  )
}

const FormGrid: FC = ({ children }) => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">{children}</div>
  )
}

const Simulation: FC<SimulationProps> = (props) => {
  const [simParams, setSimParams] = useState<SimulationProps>(
    {
      initialPrice: props.initialPrice,
      initialTokens: props.initialTokens,
      apy: props.apy,
      rewardVolatility: props.rewardVolatility,
      rewardDrift: props.rewardDrift,
      price: props.price,
      priceVolatility: props.priceVolatility,
      priceDrift: props.priceDrift,
      backingPrice: props.backingPrice,
      backingPriceVolatility: props.backingPriceVolatility,
      backingPriceDrift: props.backingPriceDrift,
      runwayInDays: props.runwayInDays,

    }
  )
  const [simnr, setSimNr] = useState(0)
  let [searchParams, setSearchParams] = useSearchParams();
  const sim = new SimulationTimeSeries(simParams, USE_DRIFT)
  const result = sim.simulate()
  const steps = Array<number>(result.price.length)
  steps.fill(0)

  return (
    <div className={"container mx-auto"}>
      <div className={"mt-4 text-4xl flex justify-center text-center"}>
        <h1>Rebasing DAO simulator</h1>
      </div>
      <div className={"mt-4 flex justify-center text-center"}>
        <p>Modify values to create new simulation</p>
      </div>
      <Formik initialValues={simParams} onSubmit={values => {
        setSimParams(values)
        const copy = { ...values } as Record<string, any>
        if (!USE_DRIFT) {
          delete copy["priceDrift"]
          delete copy["backingPriceDrift"]
          delete copy["rewardDrift"]
          delete copy["rewardVolatility"]
        }
        setSearchParams(copy)
      }}>
        {formik => {
          return (
            <Form>
              <div className={"mt-5 ml-10 mr-10 flex flex-col justify-center"}>
                <FormGrid>
                  <NumberInput label={"Number of tokens purchased"} name={"initialTokens"}/>
                  <NumberInput label={"Purchase price"} name={"initialPrice"}/>

                  <NumberInput label={"Current token price"} name={"price"}/>
                  <NumberInput label={"Current backed price"} name={"backingPrice"}/>

                  <NumberInput label={`Token price volatility (${(simParams.priceVolatility * 100).toFixed(2)}%)`}
                               name={"priceVolatility"}
                               min={0.001} max={100} step={0.001}
                  />

                  <NumberInput
                    label={`Backed price volatility (${(simParams.backingPriceVolatility * 100).toFixed(2)}%)`}
                    name={"backingPriceVolatility"}
                    min={0.001} max={100} step={0.001}
                  />

                  {USE_DRIFT && (
                    <NumberInput
                      label={`Price drift (${((simParams.priceDrift || 0) * 100).toFixed(2)}%)`}
                      name={"priceDrift"}
                      step={0.0001}
                    />
                  )}

                  {USE_DRIFT && (
                    <NumberInput
                      label={`Backing price drift (${((simParams.backingPriceDrift || 0) * 100).toFixed(2)}%)`}
                      name={"backingPriceDrift"}
                      step={0.0001}
                    />
                  )}


                  {USE_DRIFT && (
                    <NumberInput
                      label={`Reward volatility (${((simParams.rewardVolatility || 0) * 100).toFixed(2)}%)`}
                      name={"rewardVolatility"}
                      step={0.0001}
                    />
                  )}

                  {USE_DRIFT && (
                    <NumberInput
                      label={`Reward drift (${((simParams.rewardDrift || 0) * 100).toFixed(2)}%)`}
                      name={"rewardDrift"}
                      step={0.0001}
                    />
                  )}

                  <NumberInput
                    label={`APY (Year: ${(simParams.apy * 100).toFixed(2)}%,  Day: ${(apyToDailyReward(simParams.apy) * 100).toFixed(2)}%)`}
                    name={"apy"}
                    step={1}
                  />

                  <NumberInput label={`Runway in days`}
                               name={"runwayInDays"}
                               min={1} max={500} step={1}
                  />


                </FormGrid>
                <div className={"mt-8 flex justify-center flex-grow"}>
                  <button
                    type={"submit"}
                    className={"bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded grow"}
                    onClick={it => formik.submitForm().then(() => setSimNr(simnr + 1))}
                  >
                    Simulate
                  </button>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>

      <div className={"mt-12 mb-12 grid grid-cols-1 lg:grid-cols-2 gap-2"}>

        <ResponsiveContainer aspect={6 / 2}>
          <LineChart width={600} height={200} data={steps.map((_, idx) => {
            return {
              price: parseFloat(result.price[idx].toFixed(2)),
              backing: parseFloat(result.backingValue[idx].toFixed(2))
            }
          })} margin={{ right: 30 }}>
            <CartesianGrid strokeDasharray="3 3"/>
            <YAxis style={{ fontSize: "0.7rem", fontFamily: "monospace" }}
                   label={{ value: "Price", angle: -90, position: 'insideLeft' }}/>
            <XAxis style={{ fontSize: "0.7rem", fontFamily: "monospace" }}
                   label={{ value: "Days", position: 'insideBottom', offset: 0 }}/>
            <Tooltip labelFormatter={(label, foo) => `Day: ${label}`}/>
            <Legend verticalAlign="top" height={36}/>
            <Line name="Token Price" dot={false} dataKey="price" stroke="red" isAnimationActive={false}/>
            <Line name="Backing Price" dot={false} dataKey="backing" stroke="blue" isAnimationActive={false}/>
          </LineChart>
        </ResponsiveContainer>

        <ResponsiveContainer aspect={6 / 2}>
          <LineChart width={600} height={200} data={steps.map((_, idx) => {
            return { returns: parseFloat(result.returns[idx].toFixed(2)) }
          })} margin={{ right: 30 }}>
            <CartesianGrid strokeDasharray="3 3"/>
            <YAxis style={{ fontSize: "0.7rem", fontFamily: "monospace" }}/>
            <XAxis style={{ fontSize: "0.7rem", fontFamily: "monospace" }}
                   label={{ value: "Days", position: 'insideBottom', offset: 0 }}/>
            <Tooltip labelFormatter={(label, foo) => `Day: ${label}`}/>
            <Legend verticalAlign="top" height={36}/>
            <ReferenceLine y={1} label="Break Even" stroke="red" strokeDasharray="3 3"/>
            <ReferenceLine y={2} label="2 X" stroke="red" strokeDasharray="3 3"/>
            <ReferenceLine y={5} label="5 X" stroke="red" strokeDasharray="3 3"/>
            <ReferenceLine y={10} label="10 X" stroke="red" strokeDasharray="3 3"/>
            <ReferenceLine y={20} label="20 X" stroke="red" strokeDasharray="3 3"/>
            <ReferenceLine y={50} label="50 X" stroke="red" strokeDasharray="3 3"/>
            <ReferenceLine y={100} label="100 X" stroke="red" strokeDasharray="3 3"/>
            <ReferenceLine y={200} label="200 X" stroke="red" strokeDasharray="3 3"/>
            <ReferenceLine y={500} label="500 X" stroke="red" strokeDasharray="3 3"/>
            <ReferenceLine y={1000} label="1000 X" stroke="red" strokeDasharray="3 3"/>
            <Line name="Returns" dot={false} dataKey="returns" stroke="green" isAnimationActive={false}/>
          </LineChart>
        </ResponsiveContainer>

        {USE_DRIFT && (
          <ResponsiveContainer aspect={6 / 2}>
            <LineChart width={600} height={200} data={steps.map((_, idx) => {
              return { reward: result.reward[idx] }
            })} margin={{ right: 30 }}>
              <CartesianGrid strokeDasharray="3 3"/>
              <YAxis style={{ fontSize: "0.7rem", fontFamily: "monospace" }}/>
              <XAxis style={{ fontSize: "0.7rem", fontFamily: "monospace" }}
                     label={{ value: "Days", position: "insideBottom", offset: 0 }}/>
              <Tooltip labelFormatter={(label, foo) => `Day: ${label}`}/>
              <Legend verticalAlign="top" height={36}/>
              <Line name="Rebase Reward" dot={false} dataKey="reward" stroke="blue" isAnimationActive={false}/>
            </LineChart>
          </ResponsiveContainer>
        )}

        <ResponsiveContainer aspect={6 / 2}>
          <LineChart width={600} height={200} data={steps.map((_, idx) => {
            return { tokens: parseFloat(result.tokens[idx].toFixed(6)) }
          })} margin={{ right: 30 }}>
            <CartesianGrid strokeDasharray="3 3"/>
            <YAxis style={{ fontSize: "0.7rem", fontFamily: "monospace" }}/>
            <XAxis style={{ fontSize: "0.7rem", fontFamily: "monospace" }}
                   label={{ value: "Days", position: 'insideBottom', offset: 0 }}/>
            <Tooltip labelFormatter={(label, foo) => `Day: ${label}`}/>
            <Legend verticalAlign="top" height={36}/>
            <Line name="Tokens" dot={false} dataKey="tokens" stroke="blue" isAnimationActive={false}/>
          </LineChart>
        </ResponsiveContainer>


        <ResponsiveContainer aspect={6 / 2}>
          <LineChart width={600} height={200} data={steps.map((_, idx) => {
            return { value: parseFloat(result.value[idx].toFixed(2)) }
          })} margin={{ right: 30 }}>
            <CartesianGrid strokeDasharray="3 3"/>
            <YAxis style={{ fontSize: "0.7rem", fontFamily: "monospace" }}/>
            <XAxis style={{ fontSize: "0.7rem", fontFamily: "monospace" }}
                   label={{ value: "Days", position: 'insideBottom', offset: 0 }}/>
            <Tooltip labelFormatter={(label, foo) => `Day: ${label}`}/>
            <Legend verticalAlign="top" height={36}/>
            <Line name="Portfolio Value" dot={false} dataKey="value" stroke="green" isAnimationActive={false}/>
          </LineChart>
        </ResponsiveContainer>

      </div>
    </div>
  )


}

const useCalculatorParams = (params: Partial<SimulationProps>) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const props: SimulationProps = {
    initialTokens: parseFloat(searchParams.get("initialTokens") || "1"),
    initialPrice: parseFloat(searchParams.get("initialPrice") || "3000"),

    price: parseFloat(searchParams.get("price") || "3000"),
    priceVolatility: parseFloat(searchParams.get("priceVolatility") || "0.1"),
    priceDrift: USE_DRIFT ? parseFloat(searchParams.get("priceDrift") || "-0.000") : 0.000,

    backingPrice: parseFloat(searchParams.get("backingPrice") || "1500"),
    backingPriceVolatility: parseFloat(searchParams.get("backingPriceVolatility") || "0.05"),
    backingPriceDrift: USE_DRIFT ? parseFloat(searchParams.get("backingPriceDrift") || "0.00") : 0.00,

    apy: parseFloat(searchParams.get("apy") || "100"),
    rewardVolatility: USE_DRIFT ? parseFloat(searchParams.get("rewardDrift") || "0.02") : 0.02,
    rewardDrift: USE_DRIFT ? parseFloat(searchParams.get("rewardDrift") || "-1") : -1,

    runwayInDays: parseFloat(searchParams.get("runwayInDays") || "300"),

    ...params
  }

  return props
}

const SimulationApp = () => {
  const props = useCalculatorParams({})

  return (
    <div className="App">
      <Simulation {...props}/>
    </div>
  )
}

const InvictusApp = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const props = useCalculatorParams({
    initialPrice: parseFloat(searchParams.get("initialPrice") || "300"),
    price: parseFloat(searchParams.get("price") || "280"),
    backingPrice: parseFloat(searchParams.get("backingPrice") || "160"),
    apy: parseFloat(searchParams.get("apy") || "260"),
    runwayInDays: parseFloat(searchParams.get("runwayInDays") || "300"),
  })

  return (
    <div className="App">
      <Simulation {...props} />
    </div>
  )
}


function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SimulationApp/>}/>
        <Route path="/invictus" element={<InvictusApp/>}/>
      </Routes>
    </BrowserRouter>
  )


}

export default App;
